.hidden {
    display: 'none';
    height: 0px;
    opacity: 0;
}

.menu-icon {
    display: none;
    height: 0px;
    opacity: 0px;
}

.nav {
    position: fixed;
    top: 0;
    width: 100%;
    transition: opacity .5s ease-in;
    z-index: 1;
}

.nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: navy;
    text-align: center;
}

.nav li {
    display: inline-block;
}

.nav li a {
    display: block;
    padding: 20px 14px;
    text-decoration: none;
    color: white;
}

.nav li a:hover {
    background-color: white;
    color: navy;
}

.nav .active {
    width: 300px;
    background-color: lightblue;
}

@media (max-width: 767px) {
    .nav ul {
        display: flex;
        flex-direction: column;
    }

    .menu-icon {
        display: block;
        height: 48px;
        background-color: navy;
        color: white;
        cursor: pointer;
        text-align: center;
        font-size: 24px;
        line-height: 2em;
        z-index: 2;
        opacity: 1;
    }
}