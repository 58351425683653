@import url(https://fonts.googleapis.com/css?family=Comfortaa&display=swap);
@import url(https://fonts.googleapis.com/css?family=Glass+Antiqua&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
    /* font-family: 'Glass Antiqua', cursive; */
    font-family: 'Comfortaa', cursive;
}

p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.6;
}

body {
    background-image: url(/static/media/fresh_snow_@2X.0b312bd5.png);
    background-attachment: fixed;
    z-index: 0;
}

a {
    text-decoration: none;
}
.about-main {
    width: 100%;
    background-color: white;
}

.about-text-div {
    padding-left: 10%;
    padding-right: 10%;
}

.about-flex-container {
    display: flex;
}

.about-bio-text {
    flex-basis: 56%;
    margin-right: 2%;
}

.about-education-text {
    flex-basis: 42%;
}

@media screen and (max-width: 768px) {
    .about-flex-container {
        flex-direction: column;
    }
}
.technology-main {
    width: 100%;
    background-color: white;
}

.technology-container {
    padding-left: 10%;
    padding-right: 10%;
}
.contact-main {
    width: 100%;
    background-color: white;
}

.contact-text-div {
    padding-left: 10%;
    padding-right: 10%;
}

.contact-flex {
    display: flex;
    padding: 10px;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}

.contact-flex a {
    color: navy;
}


.contact-flex a:visited {
    color: navy;
}

.contact-flex p {
    font-size: 2em;
}

@media screen and (max-width: 768px) {
    .contact-flex p {
        font-size: 1.2em;
    }
}

@media screen and (max-width: 400px) {
    .contact-flex {
        flex-direction: column;
        text-align: center;
    }
}
/* Card Container */
.card-container {
    display: flex;
    flex-direction: column;
}



/* Title Container */
.title-container {
    height: 15em;
    width: 25em;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title-container h1 {
    color: white;
    text-align: center;
}

.title-container:hover {
    opacity: 0;
}



/* Info Container */
.info-container {
    position: absolute;
    height: 15em;
    width: 25em;
    opacity: 0;
    -webkit-transition: .5s ease;
    transition: .5s ease;
    line-height: 1.0;
    color: white;
}

.info-container h2 {
    text-align: center;
    line-height: 1.0;
}

.info-container hr {
    width: 100%;
    margin-top: .5em;
    margin-bottom: .5em;
}

.info-container p {
    font-size: 1em;
    line-height: 1.0;
}

.info-container a {
    display: block;
    text-align: center;
    text-decoration: none;
    color: blue;
    line-height: 1.0;
}

.info-container:hover, .info-container:active {
    opacity: 1;
    background-color:black;
    padding: 0 10px;
    -webkit-transform: scale(1.35);
            transform: scale(1.35);
    line-height: 1.0;
    font-size: 16px;
}

.info-container h4 {
    text-align: center;
    line-height: 1.0;
}


/* Media Queries */
@media screen and (max-width: 1200px) {
    .title-container {
        width: 80%;
    }

    .title-container h1 {
        font-size: 24px;
    }

    .info-container {
        width: 60%;
    }

    .info-container p {
        font-size: 12px;
    }

}
.projects-main {
    width: 100%;
    background-color: white;
}

.projects-container {
    padding-left: 10%;
    padding-right: 10%;
}

.card-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    justify-content: center;
    color: 'white';
}

@media screen and (max-width: 1200px) {
    .card-container {
        flex-direction: column;
    }
}
.hidden {
    display: 'none';
    height: 0px;
    opacity: 0;
}

.menu-icon {
    display: none;
    height: 0px;
    opacity: 0px;
}

.nav {
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-transition: opacity .5s ease-in;
    transition: opacity .5s ease-in;
    z-index: 1;
}

.nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: navy;
    text-align: center;
}

.nav li {
    display: inline-block;
}

.nav li a {
    display: block;
    padding: 20px 14px;
    text-decoration: none;
    color: white;
}

.nav li a:hover {
    background-color: white;
    color: navy;
}

.nav .active {
    width: 300px;
    background-color: lightblue;
}

@media (max-width: 767px) {
    .nav ul {
        display: flex;
        flex-direction: column;
    }

    .menu-icon {
        display: block;
        height: 48px;
        background-color: navy;
        color: white;
        cursor: pointer;
        text-align: center;
        font-size: 24px;
        line-height: 2em;
        z-index: 2;
        opacity: 1;
    }
}
/* Make the circle image move up and down */
@-webkit-keyframes mover {
    0% { -webkit-transform: translateY(0); transform: translateY(0); }
    100% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}
@keyframes mover {
    0% { -webkit-transform: translateY(0); transform: translateY(0); }
    100% { -webkit-transform: translateY(-10px); transform: translateY(-10px); }
}

/* Convert the hero section to a hidden navbar */
@-webkit-keyframes movehero {
    0%   {
        width: 100%; 
        top: 50%; 
        left: 50%; 
        right: 50%; 
        background: navy; 
        width: 100px;
        opacity: 1;
    }
    100% {
        width: 100%; 
        top: 0px; 
        left: 0% auto; 
        right: 0% auto; 
        background: navy; 
        width: 100%;
        opacity: 0;
    }
  }
@keyframes movehero {
    0%   {
        top: 50%; 
        left: 50%; 
        right: 50%; 
        color: #EEEEEE;
        background: navy; 
        width: 100px;
        opacity: 1;
    }
    100% {
        color: transparent;
        background: navy; 
        justify-content: center;
        width: 100%;
        height: calc(5% + 10px);
        opacity: 1;
    }
}


@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hero-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero-sub {
    font-family: 'Glass Antiqua', cursive;
    font-size: 3rem;
    padding: 3% 5% 3% 5%;
    background-color: navy;
    color: #EEEEEE;
    position: fixed;
    text-align: center;
}

.hero-sub1{
    width: 100%;
    -webkit-animation: movehero .5s ease;
            animation: movehero .5s ease;
    background-color: navy;
    color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    height: calc(5% + 10px);
    opacity: 1;
}

.circle-container {
    border: 5px solid lightblue;
    border-radius: 50%;
    padding: 15px 20px;
    -webkit-animation: mover 1s infinite alternate, imgGrow 4s ease-in;
    animation: mover 1s infinite alternate, imgGrow 4s ease-in;
    background-color: white;
    position: absolute;
    bottom: 7%;
}

.picture-container {
    position: absolute;
    bottom: 7%;
    -webkit-animation: fadeIn .75s ease-in;
            animation: fadeIn .75s ease-in;
}

@media screen and (max-width: 767px) {
    .circle-container {
        font-size: 12px;
        padding: 5px 10px;
    }

    .hero-sub {
        position: absolute;
    }
    
    .hero-sub h1 {
        font-size: 42px;
        text-align: center;
    }
}

@media (min-width: 481px) and (max-width: 900px) and (orientation: landscape) {
    .circle-container {
        visibility: hidden;
    }

    .picture-container {
        position: absolute;
        bottom: 2%;
        -webkit-animation: fadeIn .75s ease-in;
                animation: fadeIn .75s ease-in;
    }
}
