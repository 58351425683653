@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');

h1 {
    /* font-family: 'Glass Antiqua', cursive; */
    font-family: 'Comfortaa', cursive;
}

p {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.6;
}

body {
    background-image: url("./fresh_snow_@2X.png");
    background-attachment: fixed;
    z-index: 0;
}

a {
    text-decoration: none;
}