@import url('https://fonts.googleapis.com/css?family=Glass+Antiqua&display=swap');

/* Make the circle image move up and down */
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

/* Convert the hero section to a hidden navbar */
@-webkit-keyframes movehero {
    0%   {
        width: 100%; 
        top: 50%; 
        left: 50%; 
        right: 50%; 
        background: navy; 
        width: 100px;
        opacity: 1;
    }
    100% {
        width: 100%; 
        top: 0px; 
        left: 0% auto; 
        right: 0% auto; 
        background: navy; 
        width: 100%;
        opacity: 0;
    }
  }
@keyframes movehero {
    0%   {
        top: 50%; 
        left: 50%; 
        right: 50%; 
        color: #EEEEEE;
        background: navy; 
        width: 100px;
        opacity: 1;
    }
    100% {
        color: transparent;
        background: navy; 
        justify-content: center;
        width: 100%;
        height: calc(5% + 10px);
        opacity: 1;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hero-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero-sub {
    font-family: 'Glass Antiqua', cursive;
    font-size: 3rem;
    padding: 3% 5% 3% 5%;
    background-color: navy;
    color: #EEEEEE;
    position: fixed;
    text-align: center;
}

.hero-sub1{
    width: 100%;
    animation: movehero .5s ease;
    background-color: navy;
    color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    height: calc(5% + 10px);
    opacity: 1;
}

.circle-container {
    border: 5px solid lightblue;
    border-radius: 50%;
    padding: 15px 20px;
    -webkit-animation: mover 1s infinite alternate, imgGrow 4s ease-in;
    animation: mover 1s infinite alternate, imgGrow 4s ease-in;
    background-color: white;
    position: absolute;
    bottom: 7%;
}

.picture-container {
    position: absolute;
    bottom: 7%;
    animation: fadeIn .75s ease-in;
}

@media screen and (max-width: 767px) {
    .circle-container {
        font-size: 12px;
        padding: 5px 10px;
    }

    .hero-sub {
        position: absolute;
    }
    
    .hero-sub h1 {
        font-size: 42px;
        text-align: center;
    }
}

@media (min-width: 481px) and (max-width: 900px) and (orientation: landscape) {
    .circle-container {
        visibility: hidden;
    }

    .picture-container {
        position: absolute;
        bottom: 2%;
        animation: fadeIn .75s ease-in;
    }
}